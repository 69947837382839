/* General Styles */
.top-nav {
  background-color: black;
  /* height: 5vh; */
  padding-top: 5px;
  padding-bottom: 5px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.icon {
  color: white;
  font-size: 30px;
}

.logo1 {
  height: 9vh;
  width: auto;
}

.wallet, .text {
  text-decoration: none;
  background-color: white;
  border-radius: 6px;
  border: 2px solid darkgoldenrod;
  padding: 10px 20px;
}

.logoutIcon {
  display: none;
}

/* Slider Styles */
@-webkit-keyframes scroll {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
  }
}

.client-slider {
  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  padding: 2px;
}

.client-slider::before,
.client-slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.client-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.client-slider::before {
  left: 0;
  top: 0;
}

.client-slider .client-slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.client-slider .client-slide {
  height: 100px;
  width: 250px;
}
.questionMark{
  height:40px;
}
.roundImg{
  height:40px;
}
.notiece-display{
  display: none;
}
/* Responsive Styles */
@media (max-width: 768px) {

  .notiece-display{
    display: block;
  }
  /* .notiece-small{
    display:none;
  } */
  /* .top-nav {
      height: 10vh;
      display: flex;
      justify-content: space-between;
  } */

  .logoutIcon {
      display: block;
      color: rgb(0, 0, 0);
      background-color: black;
      height: 4vh;
      margin-top: 20px;
      cursor: pointer;
  }


  .icon {
      font-size: 24px;
      /* margin-right: 15px;
      margin-bottom: 5px; */
  }

  .logo1 {
      height: 6vh;
  }

  .wallet {
      font-size: 12px;
      width: 28vw;
      padding: 5px;
      /* margin: 25px; */
  }

  .client-slider {
      height: 80px;
  }

  .client-slider .client-slide {
      height: 80px;
      width: 180px;
  }

  .client-slider .client-slide-track {
      width: calc(180px * 14);
  }

  .questionMark{
    margin-left:5%;
    margin-right:10%;
    margin-bottom: 15px;
    height:60%;
  }
  .roundImg {
      display: none;
  }
}
.no-select {
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer and Edge */
}
.notiece-board-icon{
  color:#d69900;
  font-size: 32px;
}