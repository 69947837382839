.coinswitch{
  background-color: black;
}

.betSlips{
  background-color: white;
}

.pastResult {
  padding: 5px;
  border: 2px solid darkgoldenrod;
  border-radius: 5px;
  margin-bottom: 5px;
  font-weight: 500;
}
.coin{
    background-color: white;
    color: black;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 2em;
}
.leftBox{
    background-color: black;
    float: left;
    height: 90vh;
   width: 18vw;
}
.rightBox{
    background-color: black;
    float: right;
    height: 90vh;
    width: 18vw;
}
.whiteBox{
    background-color: white;
    height: 70vh;
    width: 14vw;
    
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.timer-display{
    font-family: 'Orbitron', sans-serif;
    font-size: 2em;
    box-shadow: 0 0 10px darkgoldenrod;
    background-color: white;
    color: black;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.CoinInnerBox
{
    background-color: white;
    height: 55vh;
    max-height: 55vh;
    width: 10vw;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow-y: auto;

}
.roundHistory{
    background-color: black;
    color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.gameInterface{
    background-color:black;
    height: 90vh;
    width: 85vw;
}
.gameInnerBox{
    background-color: white;
    height: 85vh;
    width: 80vw;
    color: blanchedalmond;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.timeBtn{
    background-color: black;
    color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;  
}
.desc{
    color: black;
}

#coin {
  position: relative;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  cursor: pointer;
  transition: transform 1s ease-in;
  transform-style: preserve-3d;
}

#coin div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3), 0 12px 20px -10px rgba(0, 0, 0, 0.4);
  position: absolute;
  backface-visibility: hidden;
}

.side-a {
  background-image: url('../../images/coinHead.png');
  background-size: cover;
  z-index: 100;
  width: 100px;
  height: 100px;
}

.side-b {
  
  background-image: url('../../images/coinTail.png');
  background-size: cover;
  transform: rotateY(180deg);
  width: 100px;
  height: 100px;
}

#coin.heads {
  animation: flipHeads 3s ease-out forwards;
  -webkit-animation: flipHeads 3s ease-out forwards;
}

#coin.tails {
  animation: flipTails 3s ease-out forwards;
}

@keyframes flipHeads {
  from { transform: rotateY(0); }
  to { transform: rotateY(1800deg); }
}

@keyframes flipTails {
  from { transform: rotateY(0); }
  to { transform: rotateY(1980deg); }
}

.forheads,.fortails {
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px;
}

.selection-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.counter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.counter-btn {
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
}

.values {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}

.value-btn {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
}

.select-btn-heads,.select-btn-tails {
  background-color: green;
  color: white;
  border: 2px solid yellow;
  border-radius: 10px;
  width: 100px;
  height: 50px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.win, .lose {
  background: linear-gradient(to bottom, #ffcc00, #ff9900);
  height: 50vh;
  width: 20vw;
  border-radius: 10px;
  text-align: center;
  
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.Coin-modal {
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  z-index: 1001;
}
.continue-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background-color: green;
}



 

@media (max-width: 768px) {

  .verticalwhiteBox{
    height: 20vh;
  }

  .sidebar-container,.whiteBox,.timer-display {
    display: none;
  }
 .small-timer-display{
 font-family: 'Orbitron', sans-serif;
 font-size: 2em;
 box-shadow: 0 0 10px darkgoldenrod;
 background-color: white;
 color: black;
 text-align: center;
 border-radius:10px ;
 -webkit-border-radius:10px ;
 -moz-border-radius:10px ;
 -ms-border-radius:10px ;
 -o-border-radius:10px ;
width: 35vw;


}
 
  .CoinInnerBox{
    background-color: black;
    width: 30vw;
    height: 15vh;
    padding: 5px;
  }

 

  .gameInnerBox
  {
    width: 100vw;
    height: 65vh;
  }
    .selection-box {
      margin-bottom: 20px;
    }
  
    .value-btn {
      margin-bottom: 5px;
    }
  
    .headsAndTails
    {
      flex-direction: column;
      align-items: center;
  
    }
    .win,.lose{
      width: 80vw;
    }
    .buttons{
      flex-direction: column;
      align-items: center;
      justify-content: end;
    }
  
    
    .headsAndTails
    {
      flex-direction: column;
      align-items: center;
    }
    .timer-display{
     
      font-size: 1em;
   
  }
  .CoinInnerBox{
    margin-bottom: 10vh;
    width: 110%;
  }
  .gameInterface{
    background-color:white;
    height: 100vh;
    width: 85vw;
}

 .leftBox,.rightBox{
  background-color: white;
 }
  .rightBox{
    display: none;
  }
  }
