.homeBox{
    height: 80vh;
    width:68vw;
    background-color: black;
    flex: 5;
    background: rgb(2,1,11);
    background-image: linear-gradient(black,rgb(57, 57, 57));
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.home-feature-online-bg{
  display:flex;
  justify-content: space-between;
}
.online-count{
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  color:rgb(255, 215, 113) ;
  margin-bottom: 5px;
}
.online-name{
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  color:darkgoldenrod ;

}
.online-peopls{
  color: white;
  font-size: 60px;
  height: 40px;
  width: 40px;
}
.online-count-bg{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0px;
  border: 1px solid darkgoldenrod;
  border-radius: 50%;
  padding:5px;
  height: 110px;
  width: 110px;
}
.featuredGames-btn
{
    border: 2px solid darkgoldenrod;
    background-color: white;
    color: black;
  height:6vh ;
  width: 20vw;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
}
.lotteryimg{
  height: 16vh;
  width: 13.5vw;
}


/* new css____________________________________- */
.game-card{
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid goldenrod;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* padding-bottom: 1px;
  padding-left: 1px;
  padding-right: 1px; */

}
.game-card img{
  max-width: 250px;
}
.game-card button{
  border-radius: 5px;
  border: none;
  padding: 10px 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
background-color: goldenrod;

}
.games-container{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  margin-top:100px;

}

.gameBox
{
    border: 2px solid darkgoldenrod;
    background-color: white;
    height: 25vh;
    width: 15vw;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.game1,.game2{
    background-color: black;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.game1-btn,.game2-btn
{
    border: 2px solid darkgoldenrod;
    background-color: white;
    color: black;
  height:6vh ;
  width: 10vw;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
}
/* Extra small devices */
@media (max-width: 575.98px) { 
  .homeBox{
    height:fit-content;

  }
  .game-card{
    max-width: 350px !important;
    height: 300px !important;
  }
  .games-container{
    flex-direction: column;
    align-items: center;
    gap:20px;
    padding-bottom: 30px; 
  }
  
  .gamebtn0{
    font-size: 14px;
  }
  .homeBox
  {
    width:100vw;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
.lotteryimg{
  height: 11vh;
  width: 26vw;
}
  .featuredGames-btn
  {
    width: 50vw;

  }
  .gameBox
  {
    width: 30vw;
    height: 25vh;
  }
  .game1-btn,.game2-btn
  {
    width: 35vw;

  }
 }



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
 
  .gameBox
  {
    height:7vh;
    width: 24vw;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .gameBox
  {
    height:15vh ;
    width: 20vw;
  }
  .homeBox
  {
    width:100%;
    
  }

  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  /* Styles for large devices */ 
}
@media (min-width: 700px) {
  .homeBox{
    height: 70vh;
  }
}

