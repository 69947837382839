/* demo.css */
.dm_box {
    background-color: black;
    color: white;
    border-radius: 10px;
    border: 2px solid darkgoldenrod;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dm_input {
    background-color: inherit;
    border: 1px solid white;
    color: white;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.dm_btn {
    background-color: white;
    color: black;
    border: 2px solid darkgoldenrod;
    border-radius: 5px;
    padding: 10px;
    width: 45%;
    cursor: pointer;
}
.new_admin_upi_bg{
    background-color: black;
    padding:16px;
    border-radius: 10px;
    color:white;
}

.demoBtn:hover {
    background-color: darkgoldenrod;
    color: white;
}

/* Password visibility toggle */
.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: white;
}
.demo-btn-a{
   border: none;
   border-radius: 5px;
   height: 50px;
   font-size: 28px;
   width:220px; 
   background-color: black;
   color: rgb(251, 209, 103);
   font-weight: normal;
}
.demo-btn-a:hover{
    box-shadow: rgba(0, 0, 0, .2) 0 3px 1px -2px,rgba(0, 0, 0, .14) 0 2px 2px 0,rgba(0, 0, 0, .12) 0 1px 5px 0; 
 }