.search-box-bg{
    position: absolute;
    left: 25%;
    bottom: 10px;
}
.search-box-bg input{
    width:500px;
    height: 40px;
    border: 1px solid #ccc; 
    border-radius: 5px; 
    outline: none; 
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

}
.search-box-bg input:focus {
  border-color: #722de2; /* Change border color on focus */
  box-shadow: 0 0 2px #722de2; /* Glow effect */
}
.search-box-bg button{
    
    height: 40px;

}
.innerNotificationBox{
    position: relative;
    display: flex;
    /* overflow-y: scroll; */
    flex-direction: column;
    padding-bottom: 50px !important;
}
.messages-box-bg::-webkit-scrollbar {
    display: none;
}
.messages-box-bg{
    width:100%;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
}
.user-msg-bg-time{
    font-size: 10px;
    color: gray;
    align-self: flex-end;
}
.user-msg-bg-msg{
    font-size: 16px;
    color: white;
    background-color: #732ee1;
    border: none;
    border-radius: 15px;
    padding: 10px;
    width: fit-content;
}
.user-msg-bg{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
    align-self: flex-end;

}
.admin-msg-bg{
    align-self: flex-start;

}
.help-text{
    margin-bottom: 1rem;
}
.resolveBtn{
    background-color: black;
    border: 2px solid darkgoldenrod ;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.help-bg{
    position: relative;
}
.queryBtn{
    background-color: black;
    color: white;
    border-radius: 10px;
    border: 2px solid darkgoldenrod ;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px; 

    
}
.contactbtn{
    position:absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
}

.supportBtn{
    background-color: white;
    border: 2px solid darkgoldenrod ;
    color: black;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    height: 6vh;

}
@media (max-width:1000px){
    .search-box-bg input{
        width:250px;
        height: 40px;
    
    }
    .search-box-bg{
        position: absolute;
        left: 15%;
        bottom: 10px;
    }
}
@media (max-width:430px){
    .search-box-bg input{
        width:220px;
        height: 40px;
    
    }
    .search-box-bg{
        position: absolute;
        left: 10%;
        bottom: 10px;
    }
}
@media (max-width: 575.98px)
    {
        .notificationBtn{
            width: 36vw;
        }
.notificationBox{
    width:100%;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}  
.innerNotificationBox{
    width: 90vw;
    
}
.help-text{
    margin-bottom: 0rem;
}
}
.helpBox{
height: 10vh;

}

.admin_bg_change{
    background-color: rgb(113, 111, 111) !important;
}