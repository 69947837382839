
.adminpassheading{
    font-size: 36px !important;
    margin-bottom: 20px !important;
}
.adminpasschnagebtn{
    border: none;
    margin-top: 10px;
    border-radius: 5px;
    height: 40px;
    font-size: 18px;
    /* width:120px;  */
    background-color: black;
    color: rgb(251, 209, 103);
    font-weight: normal;
 }
 .adminpasschnagebtn:hover{
     box-shadow: rgba(0, 0, 0, .2) 0 3px 1px -2px,rgba(0, 0, 0, .14) 0 2px 2px 0,rgba(0, 0, 0, .12) 0 1px 5px 0; 
  }
  .adminpasscontainer{
    border:1px solid black;
    border-radius: 8px;
    width: fit-content;
    padding-bottom: 50px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;

  }
  .adminpasschange input{
    width:250px;
  }
  .adminpasschange p{
    margin-bottom: 5px;
    padding:0px
  }