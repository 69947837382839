* {
  box-sizing: border-box;
}
.admin {
  background-color: black;
  height: 14vh;
}

.innerBox {
  background-color: white;
  height: 10vh;
  border: 2px solid #e2b543;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adminOpt {
  background-color: black;
  color: white;
  border: 2px solid #e2b543;
  height: 4vh;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 3px;
}
.adminBox {
  background-image: linear-gradient(black, rgb(57, 57, 57));
  height: 74vh;
  width: 100vw;
}
.adminInnerBox {
  background-color: white;
  height: 70vh;
  width: 83vw;
  float: right;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 2px solid #e2b543;
}
.admin-home-active-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  margin-bottom: 10px;
}
.admin-home-active-btn {
  background-color: black;
  color: darkgoldenrod;
  border: none;
  border-radius: 10px;
}
.admin1 {
  background-color: black;
  height: 5vh;
  color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 2px solid #e2b543;
}
.restrict {
  background-color: black;
  color: white;
  border: 2px solid #e2b543;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.admin-logout {
  border: 2px solid darkgoldenrod;
  background-color: white;
  color: black;
  height: 5vh;
  width: 10vw;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.admin-logout:hover {
  background-color: #e2b543;
  color: black;
  border: 2px solid white;
}

.data-table {
  width: 100%;
  margin: 0 auto;
  border: 1px solid darkgoldenrod;
  border-radius: 10px;
  background-color: white;
  color: black;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: black;
}

thead th {
  padding: 10px;
  color: darkgoldenrod;
}

tbody td {
  padding: 10px;
  border: 1px solid #ccc;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: auto;
}

.page-item {
  list-style: none;
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  border: 2px solid darkgoldenrod;
  border-radius: 50%;
  color: white;
  background-color: black;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -moz-transition: background-color 0.3s, color 0.3s;
  -ms-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
}

.page-link:hover,
.page-item.active .page-link {
  background-color: darkgoldenrod;
  color: black;
}

.userEditBtn {
  background-color: white;
  color: black;
  border: 2px solid darkgoldenrod;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.automaticDepositBtn,
.manualDepositBtn {
  background-color: black;
  height: 15vh;
  width: 10vw;

  color: white;
  border: 2px solid #ffc107;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* admin.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-btn {
  border: none;
  padding: 10px 20px;
  /* border-radius: 5px; */
  margin: 0 10px;
  cursor: pointer;
}

.modal-btn.yes {
  background-color: inherit;
  color: black;
}
.modal-btn.yes:hover,
.modal-btn.no:hover {
  background-color: #ffc107;
  /* border:2px solid black; */
  border-radius: 5px;
}

.modal-btn.no {
  background-color: inherit;
  color: black;
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) and (max-width: 767.98px) { 
    .adminInnerBox{
        margin-left: 0;


    }

  } */

/* Styles for the hamburger menu */
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.mobile-menu.open {
  transform: translateX(0);
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu ul li {
  border-bottom: 1px solid #ddd;
}

.mobile-menu ul li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.mobile-menu ul li a:hover {
  background-color: #f4f4f4;
}

/* Hamburger icon styling */
.material-symbols-outlined {
  font-size: 24px;
  cursor: pointer;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.logout-btn:hover {
  background-color: #c82333;
}

.backbuttonadmin {
  border: none;
  background-color: #239be7;
  color: white;
  border-radius: 5px;
}

.dropdown-bg-container {
  position: relative;
  width: 200px;
}
.dropdown-heading {
  background-color: #0574f0;
  color: white;
  border-radius: 5px;
  padding: 11px;
  font-size: 18px;
}
.dropdown-options-bg {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
  /* top:30px; */
  width: 100%;
  padding: 0px;
  border-radius: 5px;
}
.dropdown-options-bg li {
  padding: 5px;
  list-style-type: none;
  padding: 10px;
  border-radius: 5px;
}
.dropdown-options-bg li:hover {
  background-color: #0574f0;
  color: white;
}
.betPendingStyle{
  color: orange !important;
}
.betSuccess{
  color:green !important;
}
.betFailed{
  color: red !important;
}
.arrow-dropdown {
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  position: absolute;
  height: 12px;
  width: 12px;
  right: 20px;
  top: 15px;
  rotate: deg();
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}
.arrow-dropdown.rotate {
  transform: rotate(-225deg);
}
