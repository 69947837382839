/* General Styles */
.adminBox {
  padding: 1rem;
}

.adminInnerBox {
  height: 70vh;
}
.highlit-button {
  /* border: 2px solid red; */
  border-radius: 10px;
  background-color: #f5b207;
  color: white;
  box-shadow: 0 8px 26px 0 rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.19);
  border: none;

}

/* Admin Withdraw Box */
.adminWithdrawBox {
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  height: 45vh;
  margin-bottom: 1rem; /* Added margin to separate boxes */
}

.adminWithdrawBox p {
  margin: 10px 0;
}

/* Status Button */
.statusWithdrawBtn {
  background-color: white;
  color: black;
  border: 2px solid darkgoldenrod;
  border-radius: 10px;
  padding: 0.5rem 1rem; /* Added padding for better appearance */
  cursor: pointer;
}

.statusWithdrawBtn:hover {
  background-color: #e2b543; /* Slightly change the background color on hover */
}

/* Info Section */
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  margin-top: 1rem; /* Add margin-top to separate from other content */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .adminWithdrawBox {
      padding: 10px;
      height: auto; /* Adjust height for smaller screens */
  }

  .statusWithdrawBtn {
      padding: 0.4rem 0.8rem; /* Adjust padding for smaller screens */
  }
}

/* Additional Styles */
.WithdrawFirstBar {
  background-color: aquamarine;
}
