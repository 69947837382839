/* Ensure the table adjusts based on the screen size */
.admin-table {
  width: 100%;
  table-layout: auto;
}

.content-container {
  margin-left: 250px; /* Adjust this value based on your sidebar width */
  padding: 0 15px; /* Add horizontal padding */
}

/* Pagination styling for large screens */
.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.dropdown-bg-container{
  z-index: 111;
}

/* Pagination styling for small screens */
