/* Ensure the table adjusts based on the screen size */
.admin-table {
  width: 100%;
  table-layout: auto;
}


.content-container {
  margin-left: 250px; /* Adjust this value based on your sidebar width */
  padding: 0 15px; /* Add horizontal padding */
}
.card-heading {
  font-weight: 600; 
  font-size: 15px; /* if you want to adjust the size too */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .content-container {
    margin-left: 0; /* Remove margin for smaller screens */
  }
}

@media (min-width: 1024px) {
  .admin-table th, .admin-table td {
    padding: 12px;
    text-align: left;
  }
}
