.chat-user-icon{
    font-size: 30px;
    color:white;
}
.chat-icon-bg{
    background-color: #bbbaba;
    width: fit-content;
    padding: 10px;
    border-radius: 50%;
}
.chat-name-mesage-bg{
    display: flex;
    flex-direction: column;
}
.chat-name-mesage-bg p{
    padding: 0px;
    margin: 0px;
}
.chat-mesage-contact{
    color:gray;
    font-size: 12px;
}
.chat-name-contact{
    color:#904ff8;
    font-weight: bold;
    font-size: 15px;
}
.user-msg-bg{
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    align-items: end;
    margin-bottom: 10px;
}
.user-msg-bg-msg{
    font-size: 15px;
    margin: 0px;
}
.user-msg-bg-time{
    font-size: 8px;
    align-self: flex-start;
    margin: 0px;
    /* color:white; */
}
.chat-contact-time{
    color:rgb(81, 81, 81);
    font-size: 12px;
}
.msg-user-contact-bg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #dad2fb;
    padding:10px;
    border-left: 4px solid #722de2;
    cursor: pointer;
}
.msg-user-contact-bg_new{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #dad2fb;
    /* padding:10px; */
    cursor: pointer;
    margin-bottom:5px;
    padding-bottom: 310px;
    align-items: center !important;
    /* position: relative; */
}
.detail_row_admin_chat{
    position: relative;
    padding-bottom: 20px;
}
.new_admin_detail_heading{
    background-color:white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    padding:8px 16px;
}
.chat-img-name-msg-bg{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.contacts-bg-container_new{
    height: 65vh;
    overflow-y: scroll;
    /* background-color: gray; */
    padding-bottom: 60px;
    /* padding-left: 40px;
    padding-right: 40px; */
}
.messages-box-bg{
    padding-top: 80px;
}
.contacts-bg-container{
    display: flex;
    flex-direction: column;
    gap:5px;
    overflow-y: scroll;
    /* padding: 10px 300px; */

}
.admin-msg-bg-admin{
    align-self: flex-end !important;
}
.user-msg-bg-admin{
    align-self: flex-start;
}
.admininnerchatbg{

    position: relative;
    display: flex;
    flex-direction: column;
    height: 68vh; 
    padding-bottom: 50px !important; 

}

.search-box-bg{
    position: absolute !important;
    bottom: 10px;
}
.messages-box-bg{
    height: 100%;
    overflow-y: scroll !important;
}
.admininner{
    height: 100%;
    overflow: scroll;
}
.msg-user-contact-unread-bg{
    background-color: #bbbaba;
}
.user_bg_change{
    background-color: rgb(113, 111, 111) !important;
}

@media(min-width:1000px){
    .contacts-bg-container{

        padding: 10px 100px;
    }
    .admininnerchatbg{

        padding: 10px 100px;
    }
}