.coinFlip-Winning{
    background-color: black;
    border: 2px solid darkgoldenrod;
    color:white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.chooseWinningCoin
{
    background-color: black;
    float: right;
    border: 2px solid darkgoldenrod;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    height: 13vh;
    width: 7vw;
}
.choose
{
    background-color: black;
    border: 2px solid darkgoldenrod;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    color: white;
}
.onHeads,.onTails{
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.onChoose
{
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #56CD56;
}



/* Highlight selected button */
.choose.selected {
    background-color: gold;
    color: black;
  }
  
  /* Default button styling */
  .choose {
    background-color: black;
    color: gold;
    border: none;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
  
  .choose:hover {
    background-color: rgb(169, 169, 169);
  }
  
  /* Timer display styling */
  .timer-display {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  /* On Heads and On Tails boxes */
  .onHeads, .onTails {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    color: red;
    font-weight: 500;
  }
  
  /* Button styling */
  .onChoose {
    background-color: rgb(9, 55, 11);
    color: white;
    border: none;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
  
  .onChoose:hover {
    background-color: gray
  }
  
  .adminBox {
    padding: 20px;
  }
  
  .adminInnerBox {
    height: 80vh; /* Ensure it takes full viewport height */
  }
  
  .navigationButton {
    width: 100%; /* Full width of the container */
    padding: 15px; /* Increase padding for larger button */
    font-size: 1.5rem; /* Larger font size */
    border: none;
    border-radius: 8px; /* Rounded corners */
    background-color: #007bff; /* Primary color */
    color: white; /* Text color */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .navigationButton:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .timer-display{
    display: block;
  }
  
  .analysisDiv{
    height:95%;
  }