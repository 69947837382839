/* General Styles */
.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.historyBox {
  height: 70vh;
  width: 68vw;
  background-color: black;
  flex: 5;
  background: rgb(2, 1, 11);
  background-image: linear-gradient(black, rgb(57, 57, 57));
  padding: 20px;
  border-radius: 10px;
  font-size: 1rem; /* Base font size */
  overflow: hidden; /* Prevent overflow */
}
.betPendingStyle{
  color:orange;
}

.leaderbtn,
.historybtn {
  font-size: 28px;
  font-weight: bold;
  color: darkgoldenrod; /* Custom color for "Transaction History" */
  background-color: rgb(0, 0, 0);
  align-self: center;
  border-radius: 10px;
}

.history1,
.history2 {
  background-color: white;
  height: 55vh;
  max-height: 55vh;
  width: 90%;
  border-radius: 10px;
  overflow-y: auto; /* Enable vertical scrolling */
  font-size: 0.9rem; /* Font size for history items */
}

.game1,
.game2 {
  background-color: black;
  border-radius: 8px;
  color: white;
  border: 2px solid darkgoldenrod;
  font-size: 1rem; /* Font size for game buttons */
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .historybtn {
    width: 40%;
    font-size: 0.8rem; /* Smaller font size for mobile */
  }
  .historyBox {
    width: 100vw;
    border-radius: 0;
    font-size: 0.8rem; /* Smaller font size for mobile */
    overflow: auto; /* Ensure scrollability */
  }
  .history1,
  .history2 {
    width: 90vw;
    font-size: 0.8rem; /* Smaller font size for mobile */
    margin: 5px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .historyBox {
    width: 100vw;
    font-size: 0.9rem; /* Adjusted font size for small devices */
    overflow: auto; /* Ensure scrollability */
  }
  .transaction-list {
    margin-right: 0;
    max-height: 70vh; /* Adjust height for mobile screens */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .historyBox {
    width: 90vw;
    font-size: 1rem; /* Default font size for tablets */
    overflow: auto; /* Ensure scrollability */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .historyBox {
    width: 68vw;
    font-size: 1rem; /* Default font size for desktops */
    overflow: hidden; /* Prevent unnecessary scrolling */
  }

  .transaction-list {
    max-height: 80vh; /* Set a maximum height for large screens */
    overflow-y: auto; /* Enable vertical scrolling */
  }
}

/* Additional Styles for Scrollable Content */
.transaction-list {
  flex-grow: 1;
  margin-right: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 80vh; /* Set a maximum height */
  overflow-y: auto; /* Ensure vertical scrolling */
}

.transaction-title {
  font-size: 20px;
  font-weight: bold;
}

.transaction-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px; /* Add margin for separation */
}

.transaction-item {
  padding: 12px 0;
  border-bottom: 1px solid #f1f1f1;
}

.transaction-item:last-child {
  border-bottom: none;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12%;
    height:80%;
    gap: 50px; /* This will give equal spacing between the buttons */
  }
  
  .big-btn {
    width: 250px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
    font-size: 18px; /* Adjust the font size as needed */
    border-radius: 5px; /* Rounded corners */
  }
  
/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .historyBox {
    max-width: 100%; /* Full width on mobile screens */
    padding: 0 10px; /* Add some padding */
    height: fit-content;
    min-height: 65vh;
  }

  .header-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .history-title {
    font-size: 24px; /* Slightly smaller font size on mobile */
  }

  .transaction-list {
    margin-right: 0;
    margin-bottom: 15px;
    max-height: 70vh; /* Adjust height for mobile screens */
  }

  .betslip-wrapper {
    width: 100%;
  }
  .button-container{
    margin-top: 30%;
  }
}

.historyBox {
  max-width: 1200px; /* Default width for larger screens */
  margin: auto;
  overflow: auto; /* Ensure content doesn't overflow */
  min-width: 40vh;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.history-title {
  font-size: 28px;
  font-weight: bold;
  color: darkgoldenrod; /* Custom color for "Transaction History" */
  background-color: lightgray;
  border-radius: 10px;
}

.leaderboard-btn {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.leaderboard-btn:hover {
  background-color: #0056b3;
}


  