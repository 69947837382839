.traderText {
  color: black;
}
.tradergame {
  background-color: black;
  height: 100vh;
  
}
 .forUPS,
.forDOWN {
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px;
} 
.select-btn-up,
.select-btn-down {
  background-color: green;
  color: white;
  border: 2px solid yellow;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 768px){
  .timer-display{
    font-size: 1em;
  }
 .upAndDown{
  flex-direction: column;
  align-items: center;
 }
.leftBox,.rightBox{
  display: none;
}
.trader-timer{
  font-family: 'Orbitron', sans-serif;
  font-size: 2em;
  box-shadow: 0 0 10px darkgoldenrod;
  background-color: white;
  color: black;
  text-align: center;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
  margin: 20px;
}
.upAndDown{
  height:70vh;
  background-color: white;
}
.forDown{
  margin-bottom: 10vh;
}

}