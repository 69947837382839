.grid-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  gap: 5px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
}

.grid-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  /* width: 80px; */
  background-color: #ffffff;
  /* border-radius: 12px; */
  font-weight: bold;
  font-size: 1rem;
  color: #4a4a4a;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
}
.lottery-timer-heading{
  color:green;
  font-weight: bold;
  font-size: 24px;
  margin:0px;
}
.timer-time{
  color:black;
  font-size: 24px;
}
.timer-time-bg{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.highlightbox{
  background-color: black;
  color: #ffb901;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.grid-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  transition: opacity 0.3s ease;
  opacity: 0;
}

.grid-box:hover::after {
  opacity: 1;
}

.pendingBet{
  color:orange;
}
.successBet{
  color:green;
}
.failBet{
  color:red;
}

.timer-bg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.lottery-main-bg-container {
  padding: 10px 80px;
}
.lottery-btn {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  color: #ffb901;
  background-color: black;
}
.lottery-btn-div {
  text-align: end;
}
.lottery-btn-back {
  height: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  color: #ffb901;
  background-color: black;
}
.tickets-bg-container::-webkit-scrollbar {
  display: none;
}

.tickets-bg-container {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  height: 50vh;
}
.ticket-bg {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
}
.betslips-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}
.pending {
  color: orange;
}
.win {
  color: green !important;
}
.lost {
  color: red !important;
  font-weight: bold !important;
}

/* Vertical Barcode Design */
.barcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
}

.bar {
  background-color: #333;
  width: 40px;
  margin: 2px 0;
}

.bar.thin {
  height: 2px;
}

.bar.medium {
  height: 4px;
}

.bar.thick {
  height: 6px;
}
.ticket-uid {
  font-weight: bold;
}
.ticket-uid span {
  font-weight: normal;
  color: gay;
}
.ticket-amount {
  font-weight: bold;
}
.ticket-amount span {
  font-weight: normal;
  color: gay;
}
.ticket-status {
  font-weight: bold;
}

.price-bg-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.price-bg-container img {
  width: 50px;
}
.prize-heading {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
}
.prize-prize {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
  color: goldenrod;
}
.prizes-bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.hide-prizebg {
  display: flex;
}
@media (max-width: 500px) {
  .hide-prizebg {
    display: hide !important;
  }
  .price-bg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .price-bg-container img {
    width: 40px;
  }
  .prize-heading {
    margin: 0px;
    font-size: 10px;
    font-weight: bold;
  }
  .prize-prize {
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
    color: goldenrod;
  }
  .prizes-bg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
  }

  .grid-box {
    font-size: 0.8rem;
  }
  .lottery-main-bg-container {
    padding: 40px 10px;
  }
  .grid-box {
    height: 30px;
    padding: 2px;
    font-size: 0.8rem;
  }
  .lottery-btn {
    padding: 8px 10px;
    font-size: 12px;
    border-radius: 5px;
  }
  .lottery-btn-back {
    height: fit-content;
    padding: 5px 10px;
  }
}
