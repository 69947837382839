#withdrawable{
    border: 2px solid black;
    background-color: lightgray;
}
#withdrawable::placeholder{
    color: rgb(0, 0, 0);
    font-weight: 500;
}
.transaction-btn{
    background-color: black;
    color: white;
    height: 6vh;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.phone-btn{
    background-color: black;
    color: white;
    padding: 2px;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.amountInput{
    height: 6vh;
    width: 30%;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.continueBtn{
    height: 8vh;
    /* width: 20vw; */
    background-color: #56CD56;
    border: 2px solid black;
    height: 55;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.amount{

    border: 2px solid black;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.amount:hover
{
    background-color: black;
    color: white;
}
.utr{
    background-color: rgb(0, 0, 0);
    color: white;
    padding:5px;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.utrInput{
    background-color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.upiId
{
    background-color: black;
    color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

}

.withdraw
{
    height: 8vh;
    width: 30vw;
    background-color: black;
    color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

}
.withdrawContinue
{
    height: 5vh;
    background-color: black;
    color: white;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.withdrawImage{
    height: 10vh;
    width: 6vw;
}
/* popup */
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 25vw;
    max-height: 70%;
    overflow: auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  .popUp{
border: 2px solid darkgoldenrod;;
  }
  @media (max-width: 575.98px) { 
    .popUp{
        border: 2px solid darkgoldenrod;;
          }
     .Modal
     {
        width: 55vw;
    max-height: 40%;

     }     

     .innerNotificationBox {
        width: 93vw;
        height: 57vh;
        transform: translateX(-15px);
    }
   
    .withdrawImage{
        height: 5vh;
        width: 10vw;
    }
  }
  .error-text {
    color: red;
  }
  .makeRequest{
    background-color: black;
    border: 2px solid darkgoldenrod;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.small-font{
    font-size: x-small;
}

@media (max-width:600px){
    .continueBtn{
    font-size: 16px;
    padding:5px 9px;
    }
    .amountInput{
        height: 6vh;
        width: 50%;
    }
}