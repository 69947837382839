 .card {
            border: 2px solid #f5b207;
            border-radius: 10px;
        }
        .highlit-button {
            /* border: 2px solid red; */
            border-radius: 10px;
            background-color: #f5b207;
            color: white;
            box-shadow: 0 8px 26px 0 rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.19);
            border: none;
        
        }
        

        .deposit-button {
            cursor: pointer;
        }
        .card img {
            width: 50px;
            height: 50px;
        }
        .card-title {
            font-weight: bold;
        }
        .card-text {
            font-size: 24px;
            font-weight: bold;
        }
        .adminDepositBox{
            background-color: black;
            height: 55vh;
            color: white;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
}
.statusBtn{
    background-color: white;
    color: black;
    border: 2px solid darkgoldenrod;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}